.styled-calendar-container {
  width: 80%;
  height: 657px;
  border: none;
  margin: 30px;
}

@media only screen and (max-width: 798px) {
  .styled-calendar-container {
    width: 80%;
    height: 300px;
    border: none;
    margin: 30px;
  }
}
.cohort-calendar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cohort-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cohort-faux-title {
  width: 100%;
  height: 75px;
}

.cohort-image {
  image-rendering: optimizeSpeed;
  width: 140px;
  height: 80px;
}
.cohort-title {
  margin-top: -15px;
  font-size: 60px;
  font-family: "Noto Serif HK", serif;

  color: #e07138;
  font-weight: 300;
}
.cohort-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cohort-title-div {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.cohort-divider {
  width: 30%;
  height: 1px;
  margin-top: 20px;
  background-color: rgb(40, 93, 173);
}
.cohort-upcoming-card-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cohort-upcoming-title {
  display: flex;
  align-self: flex-start;
  margin: 0px;
  margin-top: 20px;
  font-size: 40px;
  font-weight: 300;
  color: rgb(40, 93, 173);
  font-family: "Noto Serif HK", serif;
}

.cohort-calendar-title {
  display: flex;
  margin: 0px;
  margin-top: 60px;
  font-size: 40px;
  font-weight: 300;
  color: rgb(40, 93, 173);
  font-family: "Noto Serif HK", serif;
}

.cohort-upcoming-title-sub {
  margin-top: 50px;
  font-weight: 300;
  color: rgb(40, 93, 173);
  font-family: "Noto Serif HK", serif;
}

.cohort-upcoming-divider {
  width: 100%;
  height: 1px;
  background-color: #e07138;
}
.cohort-upcoming-div {
  width: 90%;
  display: flex;
  flex-direction: column;
}
.cohort-upcoming-content {
  background-color: rgb(243, 243, 243);
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: none;
  direction: ltr; /* <-- the trick */
}

.cohort-upcoming-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Lato", sans-serif;
  background-color: white;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.25));
  margin: 10px;
  width: 250px;
}
.cohort-upcoming-card:hover {
  transform: scale(1.02);
  transition-duration: 0.2s;
}

.cohort-upcoming-image {
  object-fit: cover;
  width: 250px;
  height: 250px;
}

.cohort-upcoming-card-header {
  margin-top: 20px;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 20px;
  text-align: center;
}
.cohort-upcoming-card-date {
  text-align: center;
  font-size: 15px;
  color: #e07138;
  margin-left: 5px;
  margin-right: 5px;
}

.cohort-upcoming-card-loc {
  margin-top: 0px;
  text-align: center;
  font-size: 15px;
  color: #e07138;
  margin-left: 5px;
  margin-right: 5px;
}
.cohort-upcoming-card-button {
  width: 83px;
  background-color: rgb(40, 93, 173);
  color: white;
  border-radius: 5px;
  margin: 10px;
  height: 40px;
  border-style: none;
  cursor: pointer;
}
.cohort-upcoming-card-button:hover {
  background-color: rgb(29, 69, 129);
}

.cohort-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 30px;
}
.cohort-gallery-images {
  width: 90%;
}
.cohort-button-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cohort-button {
  background-color: #e07138;
  border-radius: 50px;
  border-style: none;
  width: 70%;
  height: 50px;
  color: white;
  font-family: "Lato", serif;
  font-size: 20px;
}
.cohort-button:hover {
  background-color: #d66025;
}

.cohort-button:active {
  background-color: #de8051;
}

.cohort-form-div {
  display: flex;
  flex-direction: column;
  align-content: space-evenly;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.1);
  font-family: "Lato", serif;
}
.cohort-inputs {
  display: flex;
  justify-content: center;
}

/* sign in */
.cohort-hidden-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cohort-hidden-title {
  margin-bottom: 0px;
  margin-top: -1px;
  font-size: 60px;
  font-family: "Noto Serif HK", serif;

  color: #e07138;
  font-weight: 300;
}
.cohort-subtitle {
  color: rgb(40, 93, 173);
}
.cohort-input {
  border-radius: 50px;
  width: 90%;
  height: 30px;
  font-size: 25px;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  margin-top: 16px;
  text-align: center;
}
