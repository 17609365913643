.about-notification {
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-notification-button {
  background-color: #e07138;
  font-size: 20px;
  color: white;
  padding: 20px;
  font-family: "Noto Serif HK", serif;
}
.about-notification-button:hover {
  background-color: #de8051;
}
.about-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-faux-title {
  width: 100%;
  height: 75px;
}

.about-image {
  width: 140px;
  height: 80px;
}
.about-title {
  margin-top: -15px;
  font-size: 60px;
  font-family: "Noto Serif HK", serif;

  color: #e07138;
  font-weight: 300;
}
.about-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-title-div {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.about-divider {
  width: 30%;
  height: 1px;
  margin-top: 20px;
  background-color: rgb(40, 93, 173);
}

.about-constitution-div {
  width: 75%;
  display: flex;
  flex-direction: column;
}

.about-constitution-title-1 {
  margin: 0px;
  margin-top: 20px;
  font-size: 40px;
  font-weight: 300;
  color: rgb(40, 93, 173);
  font-family: "Noto Serif HK", serif;
}
.about-constitution-title {
  margin: 0px;
  margin-top: 50px;
  font-size: 40px;
  font-weight: 300;
  color: rgb(40, 93, 173);
  font-family: "Noto Serif HK", serif;
}
.about-constitution-divider {
  width: 100%;
  height: 1px;
  background-color: #e07138;
}

.about-constitution-paragraph {
  font-size: 24px;

  color: rgb(40, 93, 173);
}
.about-span-sub {
  font-size: 30px;
  color: #e07138;
}

.about-member-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 600px;
  background-color: rgb(40, 93, 173);
  margin-top: 50px;
}
.about-member-section-title {
  margin: 0px;
  margin-top: 30px;
  font-family: "Noto Serif HK", serif;
  color: white;
  font-size: 50px;
}
.about-member-section-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.about-member-section-card-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  height: 465px;
  background-color: #e07138;
  border-radius: 50px;
  margin-right: 30px;
}

.about-member-section-card-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  height: 465px;
  background-color: #e07138;
  border-radius: 50px;
  margin-right: 30px;
}

.about-member-section-card-3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  height: 465px;
  background-color: #e07138;
  border-radius: 50px;
}

.about-member-section-card-image {
  width: 170px;
  height: 230px;
  margin: -10px;
}
.about-member-section-card-top {
  display: flex;
  flex-direction: row;
}
.about-member-section-card-bot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}
.about-member-section-card-bot-quote {
  font-family: "Lato", serif;
  color: white;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  font-style: italic;
}
.about-member-section-card-bot-name-1 {
  font-family: "Lato", serif;
  color: white;
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 30px;
}
.about-member-section-card-bot-name-2 {
  font-family: "Lato", serif;
  color: white;
  font-weight: 300;
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.about-member-section-card-bot-name-3 {
  font-family: "Lato", serif;
  color: white;
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 25px;
}
.about-member-section-card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Serif HK", serif;
  color: white;
  font-weight: 600;
  font-size: 30px;
  margin: 30px;
  text-align: center;
}
.about-faq-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  background-image: url("../assets/white-abstract-background_23-2148808256.webp");

  /* Set a specific height */
  min-height: 550px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
.about-faq-title {
  text-align: center;
  font-size: 40px;
  font-weight: 300;
  color: rgb(40, 93, 173);
  font-family: "Noto Serif HK", serif;
}
.about-faq-questions {
  color: rgb(40, 93, 173);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-family: "Noto Serif HK", serif;
}

.about-faq-question-1 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  margin-bottom: 30px;
}
.about-faq-question-2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  margin-bottom: 30px;
}

.about-faq-question {
  margin: 30px;
}

.about-faq-question-header {
  font-size: 30px;
  font-weight: 700;
}

.about-faq-question-content {
  font-size: 20px;
  font-weight: 400;
  font-family: "Lato", serif;
  margin: 10px;
}

.about-faq-divider {
  width: 45%;
  height: 1px;
  background-color: #e07138;
}
.about-faq-div-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px;
}

@media only screen and (max-width: 825px) {
  .about-faq-question-1 {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 30px;
  }
  .about-member-section-card-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    height: 455px;
    background-color: #e07138;
    border-radius: 50px;
  }
}

@media only screen and (max-width: 625px) {
  .about-faq-question-1 {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin-bottom: 30px;
  }
  .about-member-section-card-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    height: 455px;
    background-color: #e07138;
    border-radius: 50px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 500px) {
  .about-member-section-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .about-member-section-card-top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-member-section-card-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    height: 455px;
    background-color: #e07138;
    border-radius: 50px;
    margin-right: 0px;
  }
  .about-member-section-title {
    margin: 0px;
    margin-top: 30px;
    font-family: "Noto Serif HK", serif;
    color: white;
    font-size: 35px;
  }

  .about-member-section-card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Noto Serif HK", serif;
    color: white;
    font-weight: 600;
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
    text-align: center;
  }
  .about-member-section-card-bot-quote {
    font-family: "Lato", serif;
    color: white;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
    font-style: italic;
  }
  .about-member-section-card-bot-name-1 {
    font-family: "Lato", serif;
    color: white;
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .about-member-section-card-image {
    width: 170px;
    height: 230px;
    margin: 10px;
  }
}

@media only screen and (max-width: 405px) {
  .about-member-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 600px;
    background-color: rgb(40, 93, 173);
    margin-top: 50px;
  }
  .about-member-section-card-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 350px;
    height: 455px;
    background-color: #e07138;
    border-radius: 50px;
    margin-right: 0px;
  }

  .about-member-section-title {
    margin: 0px;
    margin-top: 30px;
    font-family: "Noto Serif HK", serif;
    color: white;
    font-size: 35px;
  }

  .about-member-section-card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Noto Serif HK", serif;
    color: white;
    font-weight: 600;
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
    text-align: center;
  }
  .about-member-section-card-bot-quote {
    font-family: "Lato", serif;
    color: white;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
    font-style: italic;
  }
  .about-member-section-card-bot-name-1 {
    font-family: "Lato", serif;
    color: white;
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .about-member-section-card-image {
    width: 170px;
    height: 230px;
    margin: 10px;
  }
}

@media only screen and (max-width: 355px) {
  .about-member-section-card-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 475px;
    background-color: #e07138;
    border-radius: 50px;
    margin-right: 0px;
  }

  .about-member-section-title {
    margin: 0px;
    margin-top: 30px;
    font-family: "Noto Serif HK", serif;
    color: white;
    font-size: 35px;
  }

  .about-member-section-card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Noto Serif HK", serif;
    color: white;
    font-weight: 600;
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
    text-align: center;
  }
  .about-member-section-card-bot-quote {
    font-family: "Lato", serif;
    color: white;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
    font-style: italic;
  }
  .about-member-section-card-bot-name-1 {
    font-family: "Lato", serif;
    color: white;
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .about-member-section-card-image {
    width: 170px;
    height: 230px;
    margin: 10px;
  }
}
