.nf-container {
  display: flex;
  align-items: center;
}

.nf-div {
  padding: 10rem;
  padding-right: 15%;
  padding-left: 15%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.nf-info {
  display: flex;
  width: 50%;
  justify-content: center;
  flex-direction: column;
}

.sad-geno {
  margin: 2rem;
}

.nf-title {
  font-size: 60px;
  font-family: "Noto Serif HK", serif;
  margin-bottom: 1rem;

  color: rgb(40, 93, 173);
  font-weight: 300;
}

.nf-info-desc {
  font-size: 30px;
  font-weight: 400;
  color: #e07138;
}

.nf-info-note {
  color: rgb(40, 93, 173);
  font-size: 20px;
  font-family: "Lato", sans-serif;
}

.nf-divider {
  width: 100%;
  height: 0.5px;
  background-color: #e07138;
}

@media (max-width: 1200px) {
  .nf-div {
    flex-direction: column;
    align-items: center;
    padding-right: 5%;
    padding-left: 5%;
    text-align: center;
  }

  .sad-geno {
    margin: auto;
  }

  .nf-info {
    width: 90%;
    justify-content: center;
    flex-direction: column;
  }

  .nf-title {
    font-size: 50px;
    color: rgb(40, 93, 173);
  }
}
