.carousel {
  overflow: hidden;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.indicators {
  display: flex;
  justify-content: left;
}
.indicators > button {
  cursor: pointer;
  margin: 5px;
  border-style: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.indicators > button:hover {
  background-color: rgb(186, 186, 186);
}
.indicators > button.active {
  background-color: rgb(40, 93, 173);
  color: white;
}
.indicators > button.active:hover {
  background-color: rgb(33, 78, 146);
  color: white;
}
