.another-div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-image: url("../assets/white-abstract-background_23-2148808256.webp");

  /* Set a specific height */
  min-height: 550px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.content-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  /* background-color: white;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2); */
  margin: 10px;
  /* animation: fade-right 1s ease-in; */
}

.divider {
  margin: 5px;
  width: 1px;
  height: 400px;
  background-color: #e07138;
}

.content-div-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  /* animation: fade-left 1s ease-in; */
}

.content-div-title {
  margin: 5px;
  color: #285dad;
  font-size: 40px;
  font-family: "Noto Serif HK", serif;
}

.content-div-p {
  margin: 5px;
  font-size: 23px;
  font-family: "Lato", serif;
  text-align: center;
  color: #285dad;
  font-weight: 300;
}

.content-div-divider {
  margin: 5px;
  width: 300px;
  height: 1px;
  background-color: #e07138;
}

.content-pic {
  width: 219px;
  height: 162px;
}

@media only screen and (max-width: 842px) {
  .content-div-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }
  .content-div-title {
    margin: 5px;
    color: #285dad;
    font-size: 30px;
    font-family: "Noto Serif HK", serif;
  }
  .content-div-p {
    margin: 5px;
    font-size: 20px;
    font-family: "Lato", serif;
    text-align: center;
    color: #285dad;
    font-weight: 300;
  }
  .content-pic {
    width: 150px;
    height: 110px;
  }
  .content-div-divider {
    margin: 5px;
    width: 300px;
    height: 1px;
    background-color: #e07138;
  }
  .divider {
    margin: 5px;
    width: 1px;
    height: 300px;
    background-color: #e07138;
  }

  .another-div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-image: url("../assets/white-abstract-background_23-2148808256.webp");

    /* Set a specific height */
    min-height: 450px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media only screen and (max-width: 740px) {
  .content-div-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }
  .content-div-title {
    margin: 5px;
    color: #285dad;
    font-size: 25px;
    font-family: "Noto Serif HK", serif;
  }
  .content-div-p {
    margin: 5px;
    font-size: 16px;
    font-family: "Lato", serif;
    text-align: center;
    color: #285dad;
    font-weight: 300;
  }
  .content-pic {
    width: 100px;
    height: 70px;
  }
  .content-div-divider {
    margin: 5px;
    width: 250px;
    height: 1px;
    background-color: #e07138;
  }
  .divider {
    margin: 5px;
    width: 1px;
    height: 250px;
    background-color: #e07138;
  }

  .another-div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-image: url("../assets/white-abstract-background_23-2148808256.webp");

    /* Set a specific height */
    min-height: 450px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media only screen and (max-width: 620px) {
  .content-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    /* background-color: white;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2); */
    margin: 10px;
    /* animation: fade-right 1s ease-in; */
  }
  .content-div-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }
  .content-div-title {
    margin: 5px;
    color: #285dad;
    font-size: 30px;
    font-family: "Noto Serif HK", serif;
  }
  .content-div-p {
    margin: 5px;
    font-size: 20px;
    font-family: "Lato", serif;
    text-align: center;
    color: #285dad;
    font-weight: 300;
  }
}

@media only screen and (max-width: 450px) {
  .content-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    /* background-color: white;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2); */
    margin: 10px;
    /* animation: fade-right 1s ease-in; */
  }
  .content-div-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }
  .content-div-title {
    margin: 5px;
    color: #285dad;
    font-size: 25px;
    font-family: "Noto Serif HK", serif;
  }
  .content-div-p {
    margin: 5px;
    font-size: 15px;
    font-family: "Lato", serif;
    text-align: center;
    color: #285dad;
    font-weight: 300;
  }
  .another-div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-image: url("../assets/white-abstract-background_23-2148808256.webp");

    /* Set a specific height */
    min-height: 400px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
