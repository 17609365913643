ul {
  list-style-type: none;
  padding: 0px;
}

.secondary-bar {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-family: "Lato", sans-serif;
  height: 75px;
  background: rgb(40, 93, 173);
  background: linear-gradient(
    180deg,
    rgba(40, 93, 173, 1) 0%,
    rgba(35, 78, 143, 1) 100%
  );
  z-index: 10;
}

.secondary-bar-list {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: normal;
  color: white;
  height: 75px;
}

.secondary-bar-list-item-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  height: 75px;
  /* -webkit-animation: fadein 0.5s forwards;
  -moz-animation: fadein 0.5s forwards;
  -ms-animation: fadein 0.5s forwards;
  -o-animation: fadein 0.5s forwards;
  animation: fadein 0.5s forwards; */
}

.secondary-bar-list-item-2:hover {
  background: rgb(50, 115, 214);
  background: linear-gradient(
    180deg,
    rgba(50, 115, 214, 1) 0%,
    rgba(42, 93, 170, 1) 100%
  );
}

.secondary-bar-list-item-2:active {
  background: rgb(27, 86, 175);
  background: linear-gradient(
    180deg,
    rgb(29, 86, 171) 0%,
    rgb(25, 72, 143) 100%
  );
}

.secondary-bar-list-item-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  height: 75px;
  /* -webkit-animation: fadein 0.5s forwards;
  -moz-animation: fadein 0.5s forwards;
  -ms-animation: fadein 0.5s forwards;
  -o-animation: fadein 0.5s forwards;
  animation: fadein 0.5s forwards;
  animation-delay: 0.1s, 480ms; */
}

.secondary-bar-list-item-3:hover {
  background: rgb(50, 115, 214);
  background: linear-gradient(
    180deg,
    rgba(50, 115, 214, 1) 0%,
    rgba(42, 93, 170, 1) 100%
  );
}
.secondary-bar-list-item-3:active {
  background: rgb(27, 86, 175);
  background: linear-gradient(
    180deg,
    rgb(29, 86, 171) 0%,
    rgb(25, 72, 143) 100%
  );
}

.secondary-bar-list-item-4 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  height: 75px;
  /* -webkit-animation: fadein 0.5s forwards;
  -moz-animation: fadein 0.5s forwards;
  -ms-animation: fadein 0.5s forwards;
  -o-animation: fadein 0.5s forwards;
  animation: fadein 0.5s forwards;
  animation-delay: 0.2s, 480ms; */
}

.secondary-bar-list-item-4:hover {
  background: rgb(50, 115, 214);
  background: linear-gradient(
    180deg,
    rgba(50, 115, 214, 1) 0%,
    rgba(42, 93, 170, 1) 100%
  );
}

.secondary-bar-list-item-4:active {
  background: rgb(27, 86, 175);
  background: linear-gradient(
    180deg,
    rgb(29, 86, 171) 0%,
    rgb(25, 72, 143) 100%
  );
}


.secondary-bar-list-item-5 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  height: 75px;
  /* -webkit-animation: fadein 0.5s forwards;
  -moz-animation: fadein 0.5s forwards;
  -ms-animation: fadein 0.5s forwards;
  -o-animation: fadein 0.5s forwards;
  animation: fadein 0.5s forwards;
  animation-delay: 0.2s, 480ms; */
}

.secondary-bar-list-item-5:hover {
  background: rgb(50, 115, 214);
  background: linear-gradient(
    180deg,
    rgba(50, 115, 214, 1) 0%,
    rgba(42, 93, 170, 1) 100%
  );
}

.secondary-bar-list-item-5:active {
  background: rgb(27, 86, 175);
  background: linear-gradient(
    180deg,
    rgb(29, 86, 171) 0%,
    rgb(25, 72, 143) 100%
  );
}

.secondary-bar-list-item-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  height: 75px;
  /* -webkit-animation: fadein 0.5s forwards;
  -moz-animation: fadein 0.5s forwards;
  -ms-animation: fadein 0.5s forwards;
  -o-animation: fadein 0.5s forwards;
  animation: fadein 0.5s forwards;
  animation-delay: 0.3s, 480ms; */
}

.secondary-bar-list-item-1:hover {
  background: rgb(50, 115, 214);
  background: linear-gradient(
    180deg,
    rgba(50, 115, 214, 1) 0%,
    rgba(42, 93, 170, 1) 100%
  );
}

.secondary-bar-list-item-1:active {
  background: rgb(27, 86, 175);
  background: linear-gradient(
    180deg,
    rgb(29, 86, 171) 0%,
    rgb(25, 72, 143) 100%
  );
}

.secondary-bar-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.secondary-bar-logo-image {
  width: 55px;
  height: 55px;
  margin-right: 12px;
  margin-left: 20px;
  border-radius: 100px;
}
.secondary-bar-title {
  display: flex;
  color: white;
  font-size: 24px;
  font-weight: 100;
  align-items: center;
  font-family: "Noto Serif HK", serif;
}

.hamburger-hidden {
  display: flex;
  flex-direction: column;
  position: absolute;
  visibility: hidden;
  width: 150px;
  right: 0px;
  top: 75px;
}
.hamburger-content-hidden-div {
  background: rgb(40, 93, 173);
  background: linear-gradient(
    180deg,
    rgba(40, 93, 173, 1) 0%,
    rgba(35, 78, 143, 1) 100%
  );
}

.hamburger-content-hidden-div:hover {
  background: rgb(50, 115, 214);
  background: linear-gradient(
    180deg,
    rgba(50, 115, 214, 1) 0%,
    rgba(42, 93, 170, 1) 100%
  );
}
.hamburger-content-hidden {
  color: white;
  padding: 10px;
  text-align: right;
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
}

.hamburger-content-hidden:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
/* 
@media only screen and (max-width: 870px) {
  .secondary-bar-title {
    display: flex;
    color: white;
    font-size: 15px;
    font-weight: 100;
    align-items: center;
    font-family: "Noto Serif HK", serif;
  }
} */
