/* Center the login container */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0; /* Light background color */
}

.login-form {
  background: white;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.login-title {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #e07138;
  font-family: 'Noto Serif HK', sans-serif;
}

.login-field {
  margin-bottom: 1.5rem;
}

.login-label {
  display: block;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  text-align: center; /* or left? this is the enter password thing*/
  color: #555;
}

.login-input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.2s ease;
}

.login-input:focus {
  border-color: #007bff; /* Focus border color */
  outline: none;
}

.login-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.login-button:hover {
  background-color: #0056b3; /* Button hover effect */
}

/* Optional: Animation for fade-in effect */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.login-container {
  animation: fadeIn 0.5s ease-in;
}


.network-div {
  display: flex;
  flex-direction: column;
}

.network-faux-title {
  width: 100%;
  height: 75px;
}

.network-image {
  image-rendering: optimizeSpeed;
  width: 140px;
  height: 80px;
}

.network-title {
  margin-top: -15px;
  font-size: 60px;
  font-family: "Noto Serif HK", serif;
  color: #e07138;
  font-weight: 300;
}

.network-subtitle {
  font-size: 30px;
  font-weight: 300;
  font-family: "Noto Serif HK", serif;
  background-color: rgb(255, 255, 255);
  color: rgb(40, 93, 173);
  padding-left: 50px;
}

.network-subtitle-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
}

.network-subtitle-button {
  cursor: pointer;
}

.network-subtitle-button:hover {
  color: rgb(108, 108, 108);
}

.network-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.network-title-div {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.network-divider {
  width: 30%;
  height: 1px;
  margin-top: 20px;
  background-color: rgb(40, 93, 173);
}

.network-subtitle-div-background {
  background-color: rgb(255, 255, 255);
}

.network-subtitle-divider {
  height: 1px;
  margin-top: 20px;
  background-color: #e07138;
  margin: 0px;
  margin-top: 10px;
  margin-left: 50px;
  margin-right: 50px;
  padding-left: 50px;
}

.network-photogrid {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: rgb(255, 255, 255);
  padding-bottom: 20px;
}

.network-members-photogrid {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  background-color: rgb(255, 255, 255);
  margin-left: 30px;
  margin-right: 30px;
  padding-bottom: 20px;
  font-family: "Noto Serif HK", serif;
  font-size: 15px;
  margin-top: 20px;
}
.network-member-name-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 5px;
  border-color: rgb(199, 199, 199);
  border-style: solid;
  cursor: pointer;
}

.network-member-name-div-2 {
  display: flex;
  width: 200px;
  justify-content: center;
  cursor: pointer;
}

.network-member-name-div {
  border-color: rgb(199, 199, 199);
  border-style: solid;
  display: flex;
  width: 200px;
  margin: 5px;
  justify-content: center;
  cursor: pointer;
}

.network-member-name-div:hover {
  background-color: rgb(235, 235, 235);
  transform: scale(1.05);
  transition-duration: 0.2s;
}

.network-member-name-container:hover {
  background-color: rgb(235, 235, 235);
  transform: scale(1.05);
  transition-duration: 0.2s;
}

.network-member-picture {
  object-fit: cover;
  width: 200px;
  height: 300px;
}

.network-photogrid-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 255, 255);
  /* box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.048); */

  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.25));
  margin: 30px;
}

.network-photogrid-div:hover {
  transform: scale(1.02);
  transition-duration: 0.2s;
  background-color: rgb(248, 248, 248);
}
.network-photogrid-image {
  object-fit: cover;
  width: 275px;
  height: 325px;
}

.network-photogrid-name {
  font-family: "Lato", serif;
  color: black;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 24px;
}
.network-photogrid-p {
  color: black;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 20px; /* changed */
  font-size: 18px;
}


.external-link-icon {
  margin: 10px;
  cursor: pointer;
}

.external-link-icon-dropdown {
  cursor: pointer;
}
.hidden-div {
  font-family: "Noto Serif HK";
}

.cohort-member-name {
  text-align: center;
  margin: 20px;
}

.network-photogrid-link {
  color: #0073b1; /* LinkedIn's signature blue */
  text-decoration: none;
  display: block;
  margin-top: 0px;
  margin-bottom: 12px;
  font-size: 20px;
}

.network-photogrid-link:hover {
  text-decoration: underline;
}

