.gallery-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.gallery-faux-title {
  width: 100%;
  height: 75px;
}

.gallery-title-div {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.gallery-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery-image {
  image-rendering: optimizeSpeed;
  width: 140px;
  height: 80px;
}

.gallery-title {
  margin-top: -15px;
  font-size: 60px;
  font-family: "Noto Serif HK", serif;

  color: #e07138;
  font-weight: 300;
}

.gallery-divider {
  width: 30%;
  height: 1px;
  margin-top: 20px;
  background-color: rgb(40, 93, 173);
}
.gallery-images {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.gallery-focus-image {
  width: auto;
  max-width: 70%;
  max-height: 70%;
  cursor: pointer;
}

.gallery-focus-image-filtered {
  width: auto;
  max-width: 70%;
  max-height: 70%;
  filter: brightness(50%);
  cursor: pointer;
}

.gallery-images-child {
  width: 100%;
  display: block;
  cursor: pointer;
}

.gallery-images-child:hover {
  transform: scale(1.01);
  transition-duration: 0.2s;
}
.gallery-focus {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.841);
  position: fixed;
  z-index: 10;
  justify-content: space-evenly;
  align-items: center;
}

.gallery-focus-image-container {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: space-evenly;
  align-items: center;
}
.gallery-focus-next {
  background-color: transparent;
  border-style: none;
  cursor: pointer;
}
.gallery-focus-close {
  background-color: transparent;
  border-style: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.gallery-focus-desc {
  display: flex;
  position: fixed;
  z-index: 15;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  font-size: 40px;
  font-family: "Noto Serif HK", serif;
  filter: brightness(100%);
  transition-duration: 0.3s;
}

.gallery-subtitle {
  font-size: 30px;
  font-weight: 300;
  font-family: "Noto Serif HK", serif;
  background-color: rgb(255, 255, 255);
  color: rgb(40, 93, 173);
}

.gallery-subtitle-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gallery-subtitle-button {
  cursor: pointer;
}

.gallery-subtitle-button:hover {
  color: rgb(108, 108, 108);
}

.gallery-subtitle-divider {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #e07138;
  margin-bottom: 10px;
  height: 1px;
}

.gallery-lazy-load-box {
  background-color: rgb(236, 236, 236);
}
