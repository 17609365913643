.container {
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 80%;
    max-height: 80%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 1000;
}

.modal {
    display: flex;
    font-family: "Lato", sans-serif;
}

.close {
    background-color: rgba(0,0,0,0.7);
    color: white;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.75rem;
    font-size: 1.5rem;
    z-index: 1001;

    transition: 0.2s;
}

.close:hover {
    background-color: rgba(0,0,0,0.5);
    cursor: pointer;
}

.header {
    word-wrap: break-word;
    margin-right: 6rem;
    padding: 3rem;
    font-family: "Lato", sans-serif;
}

.desc-container {
    padding: 2rem;
    margin-bottom: 2rem;
}

.desc-container > h1 {
    color: #e07138;
    font-family: "Noto Serif HK", serif;
    font-weight: 500;
}

.desc-container > p {
    font-size: 1.25rem;
}

.desc-divider {
    width: 100%;
    height: 1px;
    background-color: rgb(40, 93, 173);
}

.event-divider {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2%;
    padding: 0.1rem;
    margin: 0;
    background-color: rgb(40, 93, 173);
}

.date {
    font-size: 1.5rem;
    background-color: rgb(40, 93, 173);
    padding: 2rem;
    margin-top: 0;
    margin-bottom: 0;
    color: white;
}

.left {
    flex: 1;
}

.right {
    background-position: center;
    background-size: cover;
    max-width: 60%;
    max-height: 60%;
}

.location {
    font-size: 1.5rem;
    background-color: #e07138;
    padding: 2rem;
    margin: 0;
    color: white;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 1000;
}

.image {
    height: 100%;
    width: 100%;
    max-height: 800px;
    max-width: 1200px;
    object-fit: cover;
}

@media (max-width: 1200px) {
    .container {
        overflow-y: auto;
        position: fixed;
        min-width: 100%;
        min-height: 100%;
    }

    .event-divider {
        position: relative;
        bottom: 0;
        width: 100%;
        height: 2%;
        padding: 1rem;
        margin: 0;
        background-color: rgb(40, 93, 173);
    }

    .modal {
        flex-direction: column;
    }

    .right {
        object-fit: contain;
        min-height: 100%;
        min-width: 100%;
    }

    .header {
        padding-top: 0;
        margin-top: -3rem;
    }

    .close {
        padding: 2rem;
        font-size: 2rem;
        background-color: #e07138;
        color: white;
        transition: 0.2s;
        position: sticky;
        margin-left: auto;
    }

    .close:hover {
        background-color: #ac4918;
    }
}