.members-div {
  display: flex;
  flex-direction: column;
}

.members-faux-title {
  width: 100%;
  height: 75px;
}

.members-image {
  image-rendering: optimizeSpeed;
  width: 140px;
  height: 80px;
}

.members-title {
  margin-top: -15px;
  font-size: 60px;
  font-family: "Noto Serif HK", serif;
  color: #e07138;
  font-weight: 300;
}

.members-subtitle {
  font-size: 30px;
  font-weight: 300;
  font-family: "Noto Serif HK", serif;
  background-color: rgb(255, 255, 255);
  color: rgb(40, 93, 173);
  padding-left: 50px;
}

.members-subtitle-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
}

.members-subtitle-button {
  cursor: pointer;
}

.members-subtitle-button:hover {
  color: rgb(108, 108, 108);
}

.members-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.members-title-div {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.members-divider {
  width: 30%;
  height: 1px;
  margin-top: 20px;
  background-color: rgb(40, 93, 173);
}

.members-subtitle-div-background {
  background-color: rgb(255, 255, 255);
}

.members-subtitle-divider {
  height: 1px;
  margin-top: 20px;
  background-color: #e07138;
  margin: 0px;
  margin-top: 10px;
  margin-left: 50px;
  margin-right: 50px;
  padding-left: 50px;
}

.members-photogrid {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: rgb(255, 255, 255);
  padding-bottom: 20px;
}

.cohort-members-photogrid {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  background-color: rgb(255, 255, 255);
  margin-left: 30px;
  margin-right: 30px;
  padding-bottom: 20px;
  font-family: "Noto Serif HK", serif;
  font-size: 15px;
  margin-top: 20px;
}
.cohort-member-name-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 5px;
  border-color: rgb(199, 199, 199);
  border-style: solid;
  cursor: pointer;
}

.cohort-member-name-div-2 {
  display: flex;
  width: 200px;
  justify-content: center;
  cursor: pointer;
}

.cohort-member-name-div {
  border-color: rgb(199, 199, 199);
  border-style: solid;
  display: flex;
  width: 200px;
  margin: 5px;
  justify-content: center;
  cursor: pointer;
}

.cohort-member-name-div:hover {
  background-color: rgb(235, 235, 235);
  transform: scale(1.05);
  transition-duration: 0.2s;
}

.cohort-member-name-container:hover {
  background-color: rgb(235, 235, 235);
  transform: scale(1.05);
  transition-duration: 0.2s;
}

.cohort-member-picture {
  object-fit: cover;
  width: 200px;
  height: 300px;
}

.members-photogrid-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 255, 255);
  /* box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.048); */

  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.25));
  margin: 30px;
}

.members-photogrid-div:hover {
  transform: scale(1.02);
  transition-duration: 0.2s;
  background-color: rgb(248, 248, 248);
}
.members-photogrid-image {
  object-fit: cover;
  width: 275px;
  height: 325px;
}

.members-photogrid-name {
  font-family: "Lato", serif;
  color: black;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 24px;
}
.members-photogrid-p {
  color: black;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 18px;
}

.external-link-icon {
  margin: 10px;
  cursor: pointer;
}

.external-link-icon-dropdown {
  cursor: pointer;
}
.hidden-div {
  font-family: "Noto Serif HK";
}

.cohort-member-name {
  text-align: center;
  margin: 20px;
}
