.main-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(40, 93, 173);
  width: 100%;
  /* height of container */
  position: absolute;
  height: 795px;
}

.slider-title-text {
  color: white;
  font-family: "Noto Serif HK", serif;
  font-size: 60px;
  z-index: 10;
  margin: 10px;
}

.slider-title-div-1 {
  background-color: rgb(40, 93, 173);
  width: fit-content;
  z-index: 5;
  margin: 10px;
  margin-left: 100px;

  -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s forwards; /* Firefox < 16 */
  -ms-animation: fadein 2s forwards; /* Internet Explorer */
  -o-animation: fadein 2s forwards; /* Opera < 12.1 */
  animation: fadein 2s forwards;
}
.arrow {
  size: 50px;
  cursor: pointer;
}
.image-button-1 {
  color: white;
  z-index: 5;
  font-size: 70px;
  height: 50px;
  background-color: transparent;
  border-style: none;
}

.image-button-1:hover {
  color: rgb(187, 187, 187);
}

.image-button-2 {
  color: white;
  z-index: 5;
  font-size: 70px;
  height: 50px;
  background-color: transparent;
  border-style: none;
}

.image-button-2:hover {
  color: rgb(187, 187, 187);
}

.slider-button {
  height: 65px;
  background-color: #e07138;
  font-family: "Lato", serif;
  font-size: 30px;
  font-weight: 300;
  color: white;
  border-style: none;
  cursor: pointer;
  z-index: 5;
  margin: 10px;
  margin-left: 100px;
  padding: 10px;
  opacity: 0;
  position: relative;
  -webkit-animation: fadein 3s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s forwards; /* Firefox < 16 */
  -ms-animation: fadein 3s forwards; /* Internet Explorer */
  -o-animation: fadein 3s forwards; /* Opera < 12.1 */
  animation: fadein 3s forwards;
  animation-delay: 0.5s;
}

.slider-button:hover {
  background-color: #de8051;
}

.slider-button:active {
  background-color: #d66025;
}

.slider-title-div-2 {
  background-color: rgb(40, 93, 173);
  width: fit-content;
  z-index: 5;
  margin: 10px;
  margin-left: 100px;
  -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s forwards; /* Firefox < 16 */
  -ms-animation: fadein 2s forwards; /* Internet Explorer */
  -o-animation: fadein 2s forwards; /* Opera < 12.1 */
  animation: fadein 2s forwards;
}

.slider-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 740px) {
  .slider-title-div-1 {
    position: relative;
    background-color: rgb(40, 93, 173);
    width: fit-content;
    z-index: 5;
    margin: 10px;
    margin-left: 60px;

    -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s forwards; /* Firefox < 16 */
    -ms-animation: fadein 2s forwards; /* Internet Explorer */
    -o-animation: fadein 2s forwards; /* Opera < 12.1 */
    animation: fadein 2s forwards;
  }
  .slider-title-div-2 {
    position: relative;
    background-color: rgb(40, 93, 173);
    width: fit-content;
    z-index: 5;
    margin: 10px;
    margin-left: 60px;
    -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s forwards; /* Firefox < 16 */
    -ms-animation: fadein 2s forwards; /* Internet Explorer */
    -o-animation: fadein 2s forwards; /* Opera < 12.1 */
    animation: fadein 2s forwards;
  }

  .slider-button {
    position: relative;
    height: 65px;
    background-color: #e07138;
    font-family: "Lato", serif;
    font-size: 30px;
    font-weight: 300;
    color: white;
    border-style: none;
    cursor: pointer;
    z-index: 5;
    margin: 10px;
    margin-left: 60px;
    padding: 10px;
    opacity: 0;

    -webkit-animation: fadein 3s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s forwards; /* Firefox < 16 */
    -ms-animation: fadein 3s forwards; /* Internet Explorer */
    -o-animation: fadein 3s forwards; /* Opera < 12.1 */
    animation: fadein 3s forwards;
    animation-delay: 0.5s;
  }
}

@media only screen and (max-width: 682px) {
  .slider-title-div-1 {
    position: relative;
    background-color: rgb(40, 93, 173);
    width: fit-content;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;

    -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s forwards; /* Firefox < 16 */
    -ms-animation: fadein 2s forwards; /* Internet Explorer */
    -o-animation: fadein 2s forwards; /* Opera < 12.1 */
    animation: fadein 2s forwards;
  }
  .slider-title-div-2 {
    position: relative;
    background-color: rgb(40, 93, 173);
    width: fit-content;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;
    -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s forwards; /* Firefox < 16 */
    -ms-animation: fadein 2s forwards; /* Internet Explorer */
    -o-animation: fadein 2s forwards; /* Opera < 12.1 */
    animation: fadein 2s forwards;
  }

  .slider-button {
    position: relative;
    height: 65px;
    background-color: #e07138;
    font-family: "Lato", serif;
    font-size: 30px;
    font-weight: 300;
    color: white;
    border-style: none;
    cursor: pointer;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;
    padding: 10px;
    opacity: 0;

    -webkit-animation: fadein 3s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s forwards; /* Firefox < 16 */
    -ms-animation: fadein 3s forwards; /* Internet Explorer */
    -o-animation: fadein 3s forwards; /* Opera < 12.1 */
    animation: fadein 3s forwards;
    animation-delay: 0.5s;
  }
}

@media only screen and (max-width: 641px) {
  .slider-title-text {
    position: relative;
    color: white;
    font-family: "Noto Serif HK", serif;
    font-size: 60px;
    z-index: 10;
    margin: 10px;
  }
  .slider-title-div-1 {
    position: relative;
    background-color: rgb(40, 93, 173);
    width: fit-content;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;

    -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s forwards; /* Firefox < 16 */
    -ms-animation: fadein 2s forwards; /* Internet Explorer */
    -o-animation: fadein 2s forwards; /* Opera < 12.1 */
    animation: fadein 2s forwards;
  }
  .slider-title-div-2 {
    position: relative;
    background-color: rgb(40, 93, 173);
    width: fit-content;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;
    -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s forwards; /* Firefox < 16 */
    -ms-animation: fadein 2s forwards; /* Internet Explorer */
    -o-animation: fadein 2s forwards; /* Opera < 12.1 */
    animation: fadein 2s forwards;
  }

  .slider-button {
    position: relative;
    height: 65px;
    background-color: #e07138;
    font-family: "Lato", serif;
    font-size: 25px;
    font-weight: 300;
    color: white;
    border-style: none;
    cursor: pointer;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;
    padding: 10px;
    opacity: 0;

    -webkit-animation: fadein 3s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s forwards; /* Firefox < 16 */
    -ms-animation: fadein 3s forwards; /* Internet Explorer */
    -o-animation: fadein 3s forwards; /* Opera < 12.1 */
    animation: fadein 3s forwards;
    animation-delay: 0.5s;
  }
}

@media only screen and (max-width: 534px) {
  .slider-title-text {
    position: relative;
    color: white;
    font-family: "Noto Serif HK", serif;
    font-size: 50px;
    z-index: 10;
    margin: 10px;
  }
  .slider-title-div-1 {
    position: relative;
    background-color: rgb(40, 93, 173);
    width: fit-content;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;

    -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s forwards; /* Firefox < 16 */
    -ms-animation: fadein 2s forwards; /* Internet Explorer */
    -o-animation: fadein 2s forwards; /* Opera < 12.1 */
    animation: fadein 2s forwards;
  }
  .slider-title-div-2 {
    position: relative;
    background-color: rgb(40, 93, 173);
    width: fit-content;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;
    -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s forwards; /* Firefox < 16 */
    -ms-animation: fadein 2s forwards; /* Internet Explorer */
    -o-animation: fadein 2s forwards; /* Opera < 12.1 */
    animation: fadein 2s forwards;
  }

  .slider-button {
    position: relative;
    height: 65px;
    background-color: #e07138;
    font-family: "Lato", serif;
    font-size: 25px;
    font-weight: 300;
    color: white;
    border-style: none;
    cursor: pointer;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;
    padding: 10px;
    opacity: 0;

    -webkit-animation: fadein 3s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s forwards; /* Firefox < 16 */
    -ms-animation: fadein 3s forwards; /* Internet Explorer */
    -o-animation: fadein 3s forwards; /* Opera < 12.1 */
    animation: fadein 3s forwards;
    animation-delay: 0.5s;
  }
}

@media only screen and (max-width: 464px) {
  .image-button-1 {
    visibility: hidden;
    color: white;
    z-index: 5;
    font-size: 35px;
    height: 10px;
    background-color: transparent;
    border-style: none;
  }
  .image-button-2 {
    visibility: hidden;
    color: white;
    z-index: 5;
    font-size: 35px;
    height: 10px;
    background-color: transparent;
    border-style: none;
  }
  .slider-title-text {
    position: relative;
    color: white;
    font-family: "Noto Serif HK", serif;
    font-size: 40px;
    z-index: 10;
    margin: 10px;
  }
  .slider-title-div-1 {
    position: relative;
    background-color: rgb(40, 93, 173);
    width: fit-content;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;

    -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s forwards; /* Firefox < 16 */
    -ms-animation: fadein 2s forwards; /* Internet Explorer */
    -o-animation: fadein 2s forwards; /* Opera < 12.1 */
    animation: fadein 2s forwards;
  }
  .slider-title-div-2 {
    position: relative;
    background-color: rgb(40, 93, 173);
    width: fit-content;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;
    -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s forwards; /* Firefox < 16 */
    -ms-animation: fadein 2s forwards; /* Internet Explorer */
    -o-animation: fadein 2s forwards; /* Opera < 12.1 */
    animation: fadein 2s forwards;
  }

  .slider-button {
    position: relative;
    height: 40px;
    background-color: #e07138;
    font-family: "Lato", serif;
    font-size: 20px;
    font-weight: 300;
    color: white;
    border-style: none;
    cursor: pointer;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;
    padding: 10px;
    opacity: 0;

    -webkit-animation: fadein 3s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s forwards; /* Firefox < 16 */
    -ms-animation: fadein 3s forwards; /* Internet Explorer */
    -o-animation: fadein 3s forwards; /* Opera < 12.1 */
    animation: fadein 3s forwards;
    animation-delay: 0.5s;
  }
}

@media only screen and (max-width: 350px) {
  .image-button-1 {
    color: white;
    z-index: 5;
    font-size: 25px;
    height: 10px;
    background-color: transparent;
    border-style: none;
  }
  .image-button-2 {
    color: white;
    z-index: 5;
    font-size: 25px;
    height: 10px;
    background-color: transparent;
    border-style: none;
  }
  .slider-title-text {
    position: relative;
    color: white;
    font-family: "Noto Serif HK", serif;
    font-size: 35px;
    z-index: 10;
    margin: 10px;
  }
  .slider-title-div-1 {
    position: relative;
    background-color: rgb(40, 93, 173);
    width: fit-content;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;

    -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s forwards; /* Firefox < 16 */
    -ms-animation: fadein 2s forwards; /* Internet Explorer */
    -o-animation: fadein 2s forwards; /* Opera < 12.1 */
    animation: fadein 2s forwards;
  }
  .slider-title-div-2 {
    position: relative;
    background-color: rgb(40, 93, 173);
    width: fit-content;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;
    -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s forwards; /* Firefox < 16 */
    -ms-animation: fadein 2s forwards; /* Internet Explorer */
    -o-animation: fadein 2s forwards; /* Opera < 12.1 */
    animation: fadein 2s forwards;
  }

  .slider-button {
    position: relative;
    height: 40px;
    background-color: #e07138;
    font-family: "Lato", serif;
    font-size: 20px;
    font-weight: 300;
    color: white;
    border-style: none;
    cursor: pointer;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;
    padding: 10px;
    opacity: 0;

    -webkit-animation: fadein 3s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s forwards; /* Firefox < 16 */
    -ms-animation: fadein 3s forwards; /* Internet Explorer */
    -o-animation: fadein 3s forwards; /* Opera < 12.1 */
    animation: fadein 3s forwards;
    animation-delay: 0.5s;
  }
}

@media only screen and (max-width: 310px) {
  .image-button-1 {
    color: white;
    z-index: 5;
    font-size: 25px;
    height: 10px;
    background-color: transparent;
    border-style: none;
  }
  .image-button-2 {
    color: white;
    z-index: 5;
    font-size: 25px;
    height: 10px;
    background-color: transparent;
    border-style: none;
  }
  .slider-title-text {
    position: relative;
    color: white;
    font-family: "Noto Serif HK", serif;
    font-size: 30px;
    z-index: 10;
    margin: 10px;
  }
  .slider-title-div-1 {
    position: relative;
    background-color: rgb(40, 93, 173);
    width: fit-content;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;

    -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s forwards; /* Firefox < 16 */
    -ms-animation: fadein 2s forwards; /* Internet Explorer */
    -o-animation: fadein 2s forwards; /* Opera < 12.1 */
    animation: fadein 2s forwards;
  }
  .slider-title-div-2 {
    position: relative;
    background-color: rgb(40, 93, 173);
    width: fit-content;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;
    -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s forwards; /* Firefox < 16 */
    -ms-animation: fadein 2s forwards; /* Internet Explorer */
    -o-animation: fadein 2s forwards; /* Opera < 12.1 */
    animation: fadein 2s forwards;
  }

  .slider-button {
    position: relative;
    height: 40px;
    background-color: #e07138;
    font-family: "Lato", serif;
    font-size: 20px;
    font-weight: 300;
    color: white;
    border-style: none;
    cursor: pointer;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;
    padding: 10px;
    opacity: 0;

    -webkit-animation: fadein 3s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s forwards; /* Firefox < 16 */
    -ms-animation: fadein 3s forwards; /* Internet Explorer */
    -o-animation: fadein 3s forwards; /* Opera < 12.1 */
    animation: fadein 3s forwards;
    animation-delay: 0.5s;
  }
}

@media only screen and (max-width: 275px) {
  .image-button-1 {
    color: white;
    z-index: 5;
    font-size: 20px;
    height: 10px;
    background-color: transparent;
    border-style: none;
  }
  .image-button-2 {
    color: white;
    z-index: 5;
    font-size: 20px;
    height: 10px;
    background-color: transparent;
    border-style: none;
  }
  .slider-title-text {
    position: relative;
    color: white;
    font-family: "Noto Serif HK", serif;
    font-size: 25px;
    z-index: 10;
    margin: 10px;
  }
  .slider-title-div-1 {
    position: relative;
    background-color: rgb(40, 93, 173);
    width: fit-content;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;

    -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s forwards; /* Firefox < 16 */
    -ms-animation: fadein 2s forwards; /* Internet Explorer */
    -o-animation: fadein 2s forwards; /* Opera < 12.1 */
    animation: fadein 2s forwards;
  }
  .slider-title-div-2 {
    position: relative;
    background-color: rgb(40, 93, 173);
    width: fit-content;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;
    -webkit-animation: fadein 2s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s forwards; /* Firefox < 16 */
    -ms-animation: fadein 2s forwards; /* Internet Explorer */
    -o-animation: fadein 2s forwards; /* Opera < 12.1 */
    animation: fadein 2s forwards;
  }

  .slider-button {
    position: relative;
    height: 40px;
    background-color: #e07138;
    font-family: "Lato", serif;
    font-size: 20px;
    font-weight: 300;
    color: white;
    border-style: none;
    cursor: pointer;
    z-index: 5;
    margin: 10px;
    margin-left: 20px;
    padding: 10px;
    opacity: 0;

    -webkit-animation: fadein 3s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s forwards; /* Firefox < 16 */
    -ms-animation: fadein 3s forwards; /* Internet Explorer */
    -o-animation: fadein 3s forwards; /* Opera < 12.1 */
    animation: fadein 3s forwards;
    animation-delay: 0.5s;
  }
}
