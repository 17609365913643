.notification-banner {
  background-color: #e07138;
  position: absolute;
  z-index: 5;
  top: 75px;
  width: 100%;
  display: flex;
}

.notification-banner:hover {
  background-color: #de8051;
}
.notification-text {
  font-size: 19px;
  margin: 15px;
  margin-left: 30px;
  color: white;
  font-family: "Noto Serif HK", serif;
}
