.footer-main-main-div {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 1) 45%,
    rgba(40, 93, 173, 1) 45%
  );
}
.bottom-text {
  display: flex;
  justify-content: center;
  color: white;
  font-weight: 100;
  font-family: "Lato", sans-serif;
  padding-bottom: 10px;
}
.footer-main-div {
  display: flex;
  align-items: center;
  height: 300px;
}

.footer-item-left {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Noto Serif HK", serif;
}

.footer-item-right {
  width: 60%;
  margin-left: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.footer-item-right-child {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  color: white;
}

.footer-item-content-info {
  font-size: 20px;
  font-weight: 100;
  text-decoration: underline;
  text-decoration-thickness: 0.1px;
}

.footer-item-content-title {
  color: white;
  font-size: 36px;
  margin-bottom: 12px;
  margin-top: 0px;
}

.footer-item-content-title-socials {
  color: white;
  font-size: 36px;
  margin-bottom: 36px;
  margin-top: 0px;
}

.footer-item-socials {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-item-left-child-title {
  font-size: 32px;
  margin-bottom: 10px;
  margin-top: 0px;
  margin-left: 5px;
  color: rgb(40, 93, 173);
}

.logo {
  width: 100px;
  height: 100px;
}

.horizontal-divider {
  width: 100%;
  height: 1px;
  background-color: #e07138;
}

.footer-item-socials {
  color: white;
}

.footer-item-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1206px) {
  .horizontal-divider {
    width: 80%;
    height: 1px;
    background-color: #e07138;
  }
}

@media only screen and (max-width: 800px) {
  .footer-item-right {
    width: 60%;
    margin-left: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}
@media only screen and (max-width: 680px) {
  .logo {
    width: 150px;
    height: 150px;
  }
}

@media only screen and (max-width: 540px) {
  .footer-main-main-div {
    background: rgba(40, 93, 173, 1);
    font-family: "Noto Serif HK", serif;
  }
  .logo {
    width: 100px;
    height: 100px;
    border-radius: 100%;
  }
  .footer-main-div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .footer-item-left-child-title {
    font-size: 32px;
    font-weight: 200;
    margin-bottom: 10px;
    margin-top: 0px;
    margin-left: 10px;
    color: rgb(255, 255, 255);
  }
  .footer-item-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .horizontal-divider {
    width: 100%;
    height: 1px;
    background-color: #e07138;
  }
}

@media only screen and (max-width: 465px) {
  .logo {
    width: 60px;
    height: 60px;
    border-radius: 100%;
  }
  .footer-item-left-child-title {
    font-size: 25px;
    font-weight: 200;
    margin-bottom: 10px;
    margin-top: 0px;
    margin-left: 10px;
    color: rgb(255, 255, 255);
  }
  .footer-item-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .horizontal-divider {
    width: 100%;
    height: 1px;
    background-color: #e07138;
  }
}
